<template>
    <div class="login-wrap">
        <div class="ms-login">
            <div class="ms-title">华立园大门-人脸重置控制面板</div>
            <el-form :model="param" :rules="rules" ref="login" label-width="0px" class="ms-content">
                <div style="margin: 18px 0">统一ID</div>
                <el-form-item label="">
                    <el-input type="textarea" :rows="6" v-model="param.desc"></el-input>
                </el-form-item>
                <div style="margin-bottom: 18px">统一ID 一行一个</div>
                <div class="login-btn">
                    <el-button type="primary" @click="submitForm()">提交</el-button>
                    <el-button type="warning" @click="cleanUCN()">清除UCN</el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import { fetchData } from '../../api/index';
export default {
    data: function () {
        return {
            param: {
                username: '',
                password: ''
            },
            rules: {
                username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
                password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
            }
        };
    },
    methods: {
        submitForm() {
            var that = this;
            if (this.param.desc == undefined || this.param.desc == '') {
                this.$message.error('UCN不能为空');
            } else {
                var query = {
                    data: {
                        index: 'batch',
                        UCN: this.param.desc
                    },
                    url: '/update_reSetFaceId.cls.php'
                };
                fetchData(query).then((res) => {
                    if (res.code == 0) {
                        this.$message.success(res.msg);
                        that.param.desc = '';
                        for (var i = 0; i < res.data.errUcn.length; i++) {
                            that.param.desc = that.param.desc + res.data.errUcn[i] + '\n';
                        }
                    } else if (res.code == -1) {
                        this.$message.error(res.msg);
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }
        },
        cleanUCN() {
            this.param.desc = '';
        }
    }
};
</script>

<style scoped>
.login-wrap {
    /* position: relative;
    width: 100%;
    height: 100%; */
    /* background-image: url(../../assets/img/login-bg.jpg); */
    background-size: 100%;
}
.ms-title {
    width: 100%;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    color: #000;
    border-bottom: 1px solid #ddd;
}
.ms-login {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 350px;
    margin: -190px 0 0 -175px;
    border-radius: 5px;
    /* background: rgba(255, 255, 255, 0.3); */
    overflow: hidden;
}
/* .ms-content {
    padding: 30px 30px;
} */
.login-btn {
    text-align: center;
    display: flex;
}
.login-btn button {
    width: 50%;
    height: 36px;
    margin-bottom: 10px;
}
.login-tips {
    font-size: 12px;
    line-height: 30px;
    color: #fff;
}
</style>